import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery, IconButton, Skeleton } from '@mui/material';
import { GamePredictionResultQueueType, GameResultTextLength } from 'src/types/apps/game/prediction/type';
import DownloadIcon from 'src/assets/images/etc/download.svg';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { toBlob, toPng } from 'html-to-image';
import { FONT_CSS_FOR_HTML_TO_IMAGE } from 'src/constants/fontConstants';

export interface ResultDetailCardHandle {
  handleDownload: () => void;
}

interface Props {
  gameResultQueue: GamePredictionResultQueueType | null;
  minHeight: number;
}

const ResultDetailCard = forwardRef<ResultDetailCardHandle, Props>(({ gameResultQueue, minHeight }, ref) => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const componentRef = useRef<HTMLDivElement>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  // 디바이스 타입을 감지하는 함수
  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  // 다운로드를 수행하는 함수 (공유 실패 시 호출)
  const fallbackDownload = (blob: Blob, fileName: string) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    console.log('이미지가 성공적으로 다운로드되었습니다.');
  };

  // 모든 이미지가 로드될 때까지 대기하는 함수
  const waitForImagesToLoad = () => {
    const images = componentRef.current?.getElementsByTagName('img') || [];
    const promises = Array.from(images).map((img) => {
      if (img.complete) return Promise.resolve();
      return new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });
    });
    return Promise.all(promises);
  };

  const handleDownload = async () => {
    if (!componentRef.current) return;

    setIsDownloading(true);

    try {
      // 모든 폰트가 로드될 때까지 대기
      await document.fonts.ready;

      // 모든 이미지가 로드될 때까지 대기
      await waitForImagesToLoad();

      // 컴포넌트에서 Blob 생성
      let blob = new Blob();
      for (let i = 0; i < 4; i++) {
        const newBlob = await toBlob(componentRef.current, {
          cacheBust: true,
          backgroundColor: 'rgba(0, 0, 0, 0)', // 투명 배경
          pixelRatio: 3,
          fontEmbedCSS: FONT_CSS_FOR_HTML_TO_IMAGE,
        });

        if (newBlob) {
          blob = newBlob;
        }
      }

      // Blob에서 File 생성
      const fileNameWithoutExtensionType = `${gameResultQueue?.game_prediction?.title_summary}_${t('description')}_${Date.now()}`;
      const fileName = `${fileNameWithoutExtensionType}.png`;

      if (isMobileDevice()) {
        const file = new File([blob], fileName, { type: blob.type });
        // 모바일 장치인 경우
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          try {
            await navigator.share({
              title: gameResultQueue?.game_prediction?.title_summary ?? '이미지 공유',
              text: fileNameWithoutExtensionType,
              files: [file],
            });
            console.log('이미지가 성공적으로 공유되었습니다.');
          } catch (shareError) {
            console.error('이미지 공유에 실패했습니다.', shareError);
            // 공유에 실패하면 다운로드 시도
            fallbackDownload(blob, fileName);
          }
        } else {
          console.log('Web Share API를 사용할 수 없거나 파일 공유를 지원하지 않습니다. 다운로드를 시도합니다.');
          fallbackDownload(blob, fileName);
        }
      } else {
        // 데스크탑인 경우 항상 다운로드
        fallbackDownload(blob, fileName);
      }
    } catch (error) {
      console.error('이미지 생성 또는 공유에 실패했습니다.', error);
    } finally {
      setIsDownloading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleDownload,
  }));

  // Calculate dynamic spacing values
  const fromTextHeight = isMdDown ? 20 : 24; // Estimated height of the "From" text in px
  const bottomSpacing = isMdDown ? 24 : 40; // Space between "From" text and bottom edge
  const gapBetweenBodyAndFrom = isMdDown ? 24 : 32; // Gap between body text and "From" text

  const totalPaddingBottom = bottomSpacing + gapBetweenBodyAndFrom + fromTextHeight;

  // Function to calculate body font size based on result_text_length and isMdDown
  const getBodyFontSize = () => {
    const resultTextLength = gameResultQueue?.game_prediction_result?.result_text_length;

    if (resultTextLength === GameResultTextLength.SHORT) {
      return isMdDown ? '16px' : '20px';
    } else if (resultTextLength === GameResultTextLength.MEDIUM) {
      return isMdDown ? '14px' : '18px';
    } else if (resultTextLength === GameResultTextLength.LONG) {
      return isMdDown ? '14px' : '17px';
    } else {
      // Default font size if result_text_length is undefined
      return isMdDown ? '14px' : '17px';
    }
  };

  return (
    <Box
      ref={componentRef}
      sx={{
        width: '100%',
        borderRadius: '36px',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden', // Hide overflow for rounded corners
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Distribute space between content and "From" text
        minHeight: `${minHeight}px`,
        // Apply background directly with opacity using multiple layers
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${gameResultQueue?.game_prediction?.game_prediction_group?.background_image_url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat-y',
        backgroundPosition: 'center',
      }}
    >
      {/* Conditionally render Skeleton or content based on gameResultQueue */}
      {!gameResultQueue ? (
        // Skeleton when gameResultQueue is null
        <Skeleton
          variant="rectangular"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 999,
            bgcolor: 'rgba(0, 0, 0, 0.3)',
          }}
        />
      ) : (
        // Content when gameResultQueue has value
        <>
          {/* Content Container */}
          <Box sx={{ position: 'relative', zIndex: 2, flex: 1, paddingBottom: `${gapBetweenBodyAndFrom}px` }}>
            {/* Download Button */}
            {!isDownloading && (
              <IconButton
                onClick={handleDownload}
                aria-label="download"
                sx={{
                  position: 'absolute',
                  top: '16px',
                  right: '16px',
                  width: isMdDown ? '32px' : '48px',
                  height: isMdDown ? '32px' : '48px',
                  bgcolor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  zIndex: 3, // Ensure it's above the background
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 1)',
                  },
                }}
              >
                <img
                  src={DownloadIcon}
                  alt="Download Icon"
                  style={{
                    width: isMdDown ? '20px' : '28px',
                    height: isMdDown ? '20px' : '28px',
                  }}
                />
              </IconButton>
            )}

            <Box sx={{ height: isMdDown ? '32px' : '32px' }} />

            {/* Sub Image */}
            <Box
              sx={{
                position: 'relative',
                height: isMdDown ? '50px' : '96px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={gameResultQueue?.game_prediction?.game_prediction_group?.sub_image_url ?? ''}
                alt="Sub Image"
                style={{
                  width: 'auto',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>

            <Box sx={{ height: isMdDown ? '12px' : '12px' }} />

            {/* Subtitle */}
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: isMdDown ? '20px' : '24px',
                color: gameResultQueue?.game_prediction?.game_prediction_group?.sub_color,
                textAlign: 'center',
                padding: { xs: '0 24px', sm: '0 30px' },
                position: 'relative',
                zIndex: 2, // Ensure it's above the background
              }}
            >
              {gameResultQueue?.game_prediction?.title_summary}
            </Typography>

            <Box sx={{ height: isMdDown ? '12px' : '20px' }} />

            {/* Body Text */}
            <Box
              sx={{
                fontWeight: 400,
                fontSize: getBodyFontSize(),
                color: 'black',
                textAlign: 'center',
                padding: { xs: '0 16px', sm: '0 40px' },
                whiteSpace: 'pre-line',
                '& p': {
                  margin: 0,
                },
                zIndex: 2, // Ensure it's above the background
              }}
            >
              <ReactMarkdown
                components={{
                  strong: ({ node, ...props }) => <span style={{ fontWeight: 700 }} {...props} />,
                  em: ({ node, ...props }) => <span style={{ fontWeight: 500, fontStyle: 'italic' }} {...props} />,
                  a: ({ node, ...props }) => <a style={{ color: 'inherit', textDecoration: 'underline' }} {...props} />,
                  // Add more elements as needed
                }}
              >
                {gameResultQueue?.game_prediction_result?.result_detail ?? ''}
              </ReactMarkdown>
            </Box>
          </Box>

          {/* "From" Text Fixed at Bottom */}
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: isMdDown ? '14px' : '18px',
              color: gameResultQueue?.game_prediction?.game_prediction_group?.sub_color,
              marginBottom: `${bottomSpacing}px`, // Space from the bottom
              textAlign: 'center',
              zIndex: 2, // Ensure it's above the background
            }}
          >
            From. {gameResultQueue?.game_speaker?.name}
          </Typography>
        </>
      )}
    </Box>
  );
});

export default ResultDetailCard;
