// src/theme/typography.js

const typography = {
  fontFamily: `'Fredoka', 'Noto Sans Variable', sans-serif`,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  button: {
    fontFamily: `'Fredoka', 'Noto Sans Variable', sans-serif`,
    fontWeight: 500,
  },
};

export default typography;
