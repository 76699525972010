import { GameSpeakerType } from '../type';

export interface GamePredictionGroupType {
  id?: number;
  number?: number | null;
  image_count_type?: string | null;
  main_image_url?: string | null;
  sub_image_url?: string | null;
  background_image_url?: string | null;
  main_color?: string | null;
  sub_color?: string | null;
  image_analysis_prompt?: string | null;
  analysis_enrichment_prompt?: string | null;
  result_extraction_header_prompt?: string | null;
  result_extraction_prompt?: string | null;
  status?: string | null;
  // 추가 항목들
  game_predictions?: GamePredictionType[] | null;
  game_prediction_counts?: GamePredictionCountType[] | null;
}

export interface GamePredictionType {
  id?: number;
  title?: string | null;
  title_summary?: string | null;
  description?: string | null;
  main_image_url?: string | null;
  language_code?: string | null;
  category1?: string | null;
  category2?: string | null;
  category3?: string | null;
  game_prediction_group_id?: number;
  status?: string;
  createdAt?: Date;
  updatedAt?: Date;
  // 추가 항목들
  game_prediction_group?: GamePredictionGroupType | null;
  game_prediction_counts?: GamePredictionCountType[] | null;
}

export interface GamePredictionFilterType {
  imageCount: GamePredictionImageCountType;
  sort: GamePredictionSortType;
  duration: GamePredictionDurationType;
}

export interface GamePredictionCountType {
  id?: number;
  play_count_week?: number;
  play_count_month?: number;
  play_count_year?: number;
  play_count_all?: number;
  share_count_week?: number;
  share_count_month?: number;
  share_count_year?: number;
  share_count_all?: number;
  game_prediction_id?: number;
  game_prediction_group_id?: number;
  language_code?: string | null;
  createdAt?: Date;
  updatedAt?: Date;
  // 추가 항목들
  game_prediction_group?: GamePredictionGroupType | null;
  game_prediction?: GamePredictionType | null;
}

export enum GamePredictionImageCountType {
  All = 'all',
  Single = 'single',
  Multi = 'multi',
}

export enum GamePredictionSortType {
  Newest = 'newest',
  totalPlaysGlobal = 'total_plays_global',
  totalPlaysLanguage = 'total_plays_local',
}

export enum GamePredictionDurationType {
  All = 'play_count_all',
  PlayCountWeek = 'play_count_week',
  PlayCountMonth = 'play_count_month',
  PlayCountYear = 'play_count_year',
}

export interface GamePredictionResultType {
  id?: number;
  game_prediction_id?: number | null;
  result_text_length: GameResultTextLength | null;
  result_detail?: string | null;
  result_summary?: string | null;
  summary_phrase?: string | null;
  keyword_list?: string | null;
  image_url_1?: string | null;
  image_url_2?: string | null;
}

export interface GamePredictionResultQueueType {
  id?: number;
  game_prediction_result?: GamePredictionResultType | null;
  game_prediction?: GamePredictionType | null;
  game_speaker?: GameSpeakerType | null;
}

export enum GameResultTextLength {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
}

export enum GameStage {
  Intro = 'intro',
  InProgress = 'in_progress',
  Result = 'result',
}
