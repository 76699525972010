// SnackbarAlert.tsx

import React from 'react';
import { Snackbar, IconButton, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ShareIcon from 'src/assets/images/etc/share_gradient_circle.svg';
import WarningIcon from 'src/assets/images/etc/warning.svg';
import CloseIcon from 'src/assets/images/etc/snackbar_close.svg';
import { createPortal } from 'react-dom';

export enum SnackbarAlertType {
  URL_COPY_SUCCESS = 'URL_COPY_SUCCESS',
  UPLOAD_IMAGE_WARNING = 'UPLOAD_IMAGE_WARNING',
  CHOOSE_ITEM_WARNING = 'CHOOSE_ITEM_WARNING',
}

interface Props {
  open: boolean;
  type: SnackbarAlertType | null;
  onClose: () => void;
}

const SnackbarAlert = ({ open, type, onClose }: Props) => {
  const { t } = useTranslation();

  if (!type) return null; // Don't render anything if no type is provided

  // Define the text and icon based on the alert type
  const getMessageAndIcon = () => {
    switch (type) {
      case SnackbarAlertType.URL_COPY_SUCCESS:
        return {
          message: t('gameUrlSuccessfullyCopied'),
          icon: <img src={ShareIcon} alt="Share Icon" style={{ width: 28, height: 28, marginRight: '8px' }} />,
        };
      case SnackbarAlertType.UPLOAD_IMAGE_WARNING:
        return {
          message: t('uploadImageBeforeProceeding'),
          icon: <img src={WarningIcon} alt="Warning Icon" style={{ width: 28, height: 28, marginRight: '8px' }} />,
        };
      case SnackbarAlertType.CHOOSE_ITEM_WARNING:
        return {
          message: t('chooseItemBeforeProceeding'),
          icon: <img src={WarningIcon} alt="Warning Icon" style={{ width: 28, height: 28, marginRight: '8px' }} />,
        };
      default:
        return { message: '', icon: null };
    }
  };

  const { message, icon } = getMessageAndIcon();

  return createPortal(
    <Snackbar open={open} autoHideDuration={3000} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#D4E3FF', // Background color
          color: '#111111',
          fontWeight: 600,
          fontSize: '14px',
          borderRadius: '8px', // Rounded corners
          padding: '8px 16px', // Padding
          boxShadow: 'none',
        }}
      >
        {/* Left Icon with 8px margin-right */}
        {icon}

        {/* Message Text */}
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          {message}
        </Typography>

        {/* Close Button with 5px margin-left */}
        <IconButton size="small" aria-label="close" color="inherit" onClick={onClose} sx={{ marginLeft: '5px' }}>
          <img src={CloseIcon} alt="Close Icon" style={{ width: 18, height: 18 }} />
        </IconButton>
      </Box>
    </Snackbar>,
    document.body, // Render the Snackbar at the root level
  );
};

export default SnackbarAlert;
