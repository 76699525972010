// Result.tsx

import React, { useState, useRef } from 'react';
import { Typography, Box, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GamePredictionResultQueueType } from 'src/types/apps/game/prediction/type';
import ResultSummaryCard, { ResultSummaryCardHandle } from './ResultSummaryCard';
import ResultDetailCard, { ResultDetailCardHandle } from './ResultDetailCard';
import ResultButtonGroup from './ResultButtonGroup';
import { getComplementaryColor, getContrastColor } from 'src/utils/colorUtils';
import { darken } from '@mui/material/styles';

interface Props {
  gameResultQueue: GamePredictionResultQueueType | null;
}

const Result = ({ gameResultQueue }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const summaryCardRef = useRef<ResultSummaryCardHandle>(null);
  const detailCardRef = useRef<ResultDetailCardHandle>(null);
  const [summaryHeight, setSummaryHeight] = useState<number>(0);

  // 두 번째 다운로드를 위한 다이얼로그 관리 state
  const [openDialog, setOpenDialog] = useState(false);

  const handleSave = async () => {
    // 첫 번째 이미지 다운로드(요약 이미지)
    if (summaryCardRef.current && summaryCardRef.current.handleDownload) {
      await summaryCardRef.current.handleDownload();
    }

    // 첫 번째 다운로드 후 다이얼로그 표시 (두 번째 다운로드 진행 여부)
    setOpenDialog(true);
  };

  const handleSecondDownload = async () => {
    setOpenDialog(false);
    // 두 번째 이미지 다운로드(상세 이미지)
    if (detailCardRef.current && detailCardRef.current.handleDownload) {
      await detailCardRef.current.handleDownload();
    }
  };

  // Handler for height change
  const handleSummaryHeightChange = (height: number) => {
    setSummaryHeight(height);
  };

  return (
    <Box bgcolor={'white'} display="flex" justifyContent="center" alignItems="center" borderRadius="24px" position="relative" flexDirection={'column'} padding={2}>
      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          paddingBottom={isSmDown ? '0px' : '24px'}
          paddingX={isSmDown ? '16px' : '24px'}
          bgcolor={'white'}
          borderRadius={'24px'}
          width={'100%'}
          sx={{
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.08)',
          }}
        >
          <Box sx={{ height: isSmDown ? '24px' : '24px' }} />
          {/* ResultSummaryCard */}
          <ResultSummaryCard ref={summaryCardRef} gameResultQueue={gameResultQueue} onHeightChange={handleSummaryHeightChange} />
          <Box sx={{ height: isSmDown ? '16px' : '40px' }} />

          {/* ResultDetailCard */}
          <ResultDetailCard ref={detailCardRef} gameResultQueue={gameResultQueue} minHeight={summaryHeight} />
          <Box sx={{ height: isSmDown ? '24px' : '24px' }} />
        </Box>
        <Box sx={{ height: isSmDown ? '24px' : '40px' }} />
        {/* Buttons */}
        {gameResultQueue && <ResultButtonGroup gameResultQueue={gameResultQueue} onSave={handleSave} />}
        <Box height={'32px'} />
      </Box>

      {/* 두 번째 다운로드를 위한 다이얼로그 */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{t('popup.imageDownloadAll.title')}</DialogTitle>
        <DialogContent>{t('popup.imageDownloadAll.description')}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            variant="outlined"
            sx={{
              textTransform: 'none',
              paddingX: 4,
              paddingY: 1,
              borderRadius: '8px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#FFFFFF',
              color: '#111111',
              borderColor: '#CCCCCC', // 적절한 경계선 색상 설정
              '&:hover': {
                backgroundColor: '#F5F5F5', // 호버 시 배경색 변경
                borderColor: '#AAAAAA', // 호버 시 경계선 색상 변경
              },
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleSecondDownload}
            variant="contained"
            sx={{
              textTransform: 'none',
              paddingX: 4,
              paddingY: 1,
              borderRadius: '8px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              bgcolor: gameResultQueue?.game_prediction?.game_prediction_group?.main_color,
              color: getContrastColor(gameResultQueue?.game_prediction?.game_prediction_group?.main_color),
              '&:hover': {
                bgcolor: darken(gameResultQueue?.game_prediction?.game_prediction_group?.main_color || '#000000', 0.1), // 호버 시 배경색 어둡게
              },
            }}
          >
            {t('download')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Result;
