import CryptoJS from 'crypto-js'; // Import crypto-js

const isPathEqualToTarget = (pathname: string, targetPath: string | null | undefined) => {
  if (targetPath == null) {
    return false;
  }
  return pathname === targetPath;
};

const isOverOneHour = (dateString: string | null): boolean => {
  // 주어진 문자열을 Date 객체로 변환
  const date = new Date(dateString ?? '');

  // 현재 시간 가져오기
  const now = new Date();

  // 두 시간의 차이를 밀리초 단위로 계산
  const diffInMilliseconds = now.getTime() - date.getTime();

  // 밀리초를 시간 단위로 변환
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

  // 차이가 1시간 이상일 경우 로그 출력
  return diffInHours > 1;
};

const cacheImages = async (srcArray: (string | null)[]): Promise<void> => {
  // srcArray의 각 src가 string인 경우에만 이미지 로드
  const promises = srcArray
    .filter((src): src is string => src !== null) // null 값을 제거
    .map((src) => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
        img.onerror = () => reject(new Error(`Failed to load image at ${src}`));
      });
    });

  // 모든 이미지 로드 완료 대기
  await Promise.all(promises);
};

const encryptCryptoValue = (id: number | undefined) => {
  if (id) {
    const secretKey = import.meta.env.VITE_CRYPTO_JS_KEY;
    return encodeURIComponent(CryptoJS.AES.encrypt(id.toString(), secretKey).toString());
  } else {
    return null;
  }
};

const decryptCryptoValue = (encryptedId: string) => {
  const secretKey = import.meta.env.VITE_CRYPTO_JS_KEY;
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedId), secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

// 숫자를 K와 M 단위로 포맷하는 함수
const formatNumber = (num: number) => {
  if (num >= 1_000_000) return `${(num / 1_000_000).toFixed(2)}M`;
  if (num >= 1_000) return `${(num / 1_000).toFixed(2)}K`;
  return num.toString();
};

/**
 * 주어진 문자열이나 객체에서 중괄호 {} 또는 대괄호 []와 따옴표 " 또는 '를 제거하고,
 * 쉼표로 구분된 항목들을 배열로 변환합니다.
 * 항목 내에 쉼표가 포함된 경우에도 올바르게 처리됩니다.
 *
 * @param input - 변환할 문자열 또는 객체 (예: {"조화로운 관계","취미 공유","창의적인 분야"}, [배변, "규칙적", "소화 시스템"], "배변,규칙적,소화 시스템")
 * @returns 문자열 배열 (예: ["조화로운 관계", "취미 공유", "창의적인 분야"], ["배변", "규칙적", "소화 시스템"])
 */
const parseStringToArray = (input: string | undefined | null | object): string[] => {
  let inputStr: string;

  // 1. 입력 타입에 따라 문자열로 변환
  if (typeof input === 'string') {
    inputStr = input;
  } else if (typeof input === 'object' && input !== null) {
    // 입력이 배열인 경우
    if (Array.isArray(input)) {
      return input.map((item) => String(item));
    }
    // 입력이 비배열 객체인 경우, 객체의 모든 문자열 값을 쉼표로 연결
    const stringValues = Object.values(input)
      .filter((value) => typeof value === 'string')
      .join(',');
    if (!stringValues) {
      // console.warn('객체 내에 문자열 값이 없습니다.');
      return [];
    }
    inputStr = stringValues;
  } else {
    // console.warn('유효한 문자열이나 객체를 입력해주세요.');
    return [];
  }

  // 2. 중괄호 {} 또는 대괄호 [] 제거
  const trimmed = inputStr.trim().replace(/^[{\[]|[}\]]$/g, '');

  if (!trimmed) {
    console.warn('입력 문자열에 내용이 없습니다.');
    return [];
  }

  // 3. 정규식을 사용하여 쉼표로 분리하되, 따옴표로 감싸진 쉼표는 무시
  const regex = /(".*?"|'.*?'|[^,]+)(?=\s*,|\s*$)/g;
  const matches = trimmed.match(regex);

  if (!matches) {
    // console.warn('항목을 찾을 수 없습니다.');
    return [];
  }

  // 4. 각 항목에서 따옴표 제거 및 공백 정리
  const items = matches.map((item) => item.trim().replace(/^["']|["']$/g, '')).filter((item) => item.length > 0); // 빈 문자열 필터링

  if (items.length === 0) {
    // console.warn('항목이 하나도 존재하지 않습니다.');
  }

  return items;
};

function removeEmojis(text: string | undefined | null) {
  return text?.replace(
    /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{1FB00}-\u{1FBFF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{FE00}-\u{FE0F}|\u{1F1E6}-\u{1F1FF}|\u{1F004}|\u{1F0CF}|\u{1F18E}|\u{1F191}-\u{1F19A}|\u{1F201}-\u{1F251}|\u{1F300}-\u{1F5FF}|\u{1F600}-\u{1F64F}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{1FB00}-\u{1FBFF}]/gu,
    '',
  );
}

export { isPathEqualToTarget, isOverOneHour, cacheImages, encryptCryptoValue, decryptCryptoValue, formatNumber, parseStringToArray, removeEmojis };
