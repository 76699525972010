import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { GamePredictionCountType, GamePredictionDurationType, GamePredictionImageCountType, GamePredictionSortType } from 'src/types/apps/game/prediction/type';
import { AppState } from 'src/store/Store';
import { fetchGamesFromService } from 'src/services/Game/Prediction/service';

interface StateType {
  filters: {
    showing: boolean;
    imageCount: GamePredictionImageCountType;
    sort: GamePredictionSortType;
    duration: GamePredictionDurationType;
  };
}

const initialState = {
  filter: {
    showing: JSON.parse(localStorage.getItem('filter_showing') || 'false'),
    imageCount: Object.values(GamePredictionImageCountType).includes(localStorage.getItem('image_count') as GamePredictionImageCountType)
      ? (localStorage.getItem('image_count') as GamePredictionImageCountType)
      : GamePredictionImageCountType.All,
    sort: Object.values(GamePredictionSortType).includes(localStorage.getItem('sort') as GamePredictionSortType)
      ? (localStorage.getItem('sort') as GamePredictionSortType)
      : GamePredictionSortType.totalPlaysLanguage,
    duration: Object.values(GamePredictionDurationType).includes(localStorage.getItem('duration') as GamePredictionDurationType)
      ? (localStorage.getItem('duration') as GamePredictionDurationType)
      : GamePredictionDurationType.All,
  },
};

export const GameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setShowing(state, action) {
      state.filter.showing = action.payload;
    },
    sortByImageCount(state, action) {
      state.filter.imageCount = action.payload;
    },
    sortBySort(state, action) {
      state.filter.sort = action.payload;
    },
    sortByDuration(state, action) {
      state.filter.duration = action.payload;
    },
    filterReset(state) {
      state.filter.imageCount = GamePredictionImageCountType.All;
      state.filter.sort = GamePredictionSortType.Newest;
      state.filter.duration = GamePredictionDurationType.All;
    },
  },
});
export const { sortByImageCount, sortBySort, sortByDuration, filterReset } = GameSlice.actions;

export default GameSlice.reducer;
