// src/store/Store.tsx

import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import LanguageReducer from './apps/LanguageSlice';
import GameReducer from './apps/game/prediction/GameSlice';
import SpeakerReducer from './apps/game/prediction/SpeakerSlice';
import AdReducer from './apps/ad/AdSlice';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from 'react-redux';

// Configure the store with reducers
export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    language: LanguageReducer, // Renamed for consistency
    ad: AdReducer, // Renamed for consistency
    game: GameReducer, // Renamed for consistency
    speaker: SpeakerReducer, // Renamed for consistency
  },
});

// Derive the AppState type from the store itself
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Create typed hooks for useDispatch and useSelector
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

// Export the store as default
export default store;
