// src/index.tsx 또는 src/index.js

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useLocation } from 'react-router-dom';
import App from './App';
import { store } from './store/Store';
import Spinner from './views/spinner/Spinner';
import { initializeI18n } from './utils/i18n'; // i18n 초기화 함수 가져오기
import Footer from './components/common/Footer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 1,
    },
  },
});

// Footer 조건부 렌더링을 위한 App 컴포넌트
const AppWithConditionalFooter = () => {
  const location = useLocation();

  const isFooterHidden = !(location.pathname === '/service/game/prediction');

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <App />
      </Suspense>
      {!isFooterHidden && <Footer />}
    </>
  );
};

// ReactDOM.createRoot() 호출
const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}
const root = ReactDOM.createRoot(rootElement);

// 비동기 초기화 및 렌더링
(async () => {
  try {
    await initializeI18n(); // i18n 초기화

    root.render(
      // <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter>
            <AppWithConditionalFooter />
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>,
      // </React.StrictMode>,
    );
  } catch (error) {
    console.error('i18n 초기화 중 오류 발생:', error);
    // 초기화 실패 시 대체 UI를 렌더링하거나, 오류 페이지로 리디렉션할 수 있습니다.
    root.render(
      // <React.StrictMode>
      <div>애플리케이션을 초기화하는 동안 오류가 발생했습니다.</div>,
      // </React.StrictMode>,
    );
  }
})();
