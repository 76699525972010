import React, { useState } from 'react';
import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Download2Icon from 'src/assets/images/etc/download.svg';
import Retry2Icon from 'src/assets/images/etc/retry.svg';
import Share2Icon from 'src/assets/images/etc/share_gradient.svg';
import { GamePredictionResultQueueType } from 'src/types/apps/game/prediction/type';
import SnackbarAlert, { SnackbarAlertType } from 'src/components/common/SnackbarAlert';
import { encryptCryptoValue } from 'src/utils/utils';
import { hexToRgba, getContrastColor } from 'src/utils/colorUtils';
import { Link, useNavigate } from 'react-router-dom';
import { IncreaseGameShareCountFromServiceType, increaseGameShareCountFromService } from 'src/services/Game/Prediction/service';

interface Props {
  gameResultQueue: GamePredictionResultQueueType;
  onSave: () => void;
}

const ResultButtonGroup: React.FC<Props> = ({ gameResultQueue, onSave }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonHeight = isSmDown ? '56px' : '66px';
  const buttonBorderRadius = isSmDown ? '8px' : '12px';
  const iconStyle = {
    width: isSmDown ? '20px' : '24px',
    height: isSmDown ? '20px' : '24px',
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const retryUrl = gameResultQueue.game_prediction?.id ? `/service/game/prediction/detail/${encryptCryptoValue(gameResultQueue.game_prediction?.id)}` : '#';

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSave = () => {
    onSave();
  };

  const handleShare = () => {
    // 1. 주소 복사
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
    // 2. Count 증가
    const gameGroupId = gameResultQueue.game_prediction?.game_prediction_group?.id;
    const gameId = gameResultQueue.game_prediction?.id;

    if (gameGroupId && gameId) {
      increaseGameShareCountFromService(IncreaseGameShareCountFromServiceType.share, gameGroupId, gameId);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: isSmDown ? '0 16px' : '0 40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {/* Save Result Image Button */}
      <Button
        variant="contained"
        startIcon={<img src={Download2Icon} style={{ ...iconStyle, filter: 'invert(100%)' }} />}
        onClick={handleSave}
        sx={{
          fontWeight: 600,
          fontSize: isSmDown ? '16px' : '20px',
          background: 'linear-gradient(-84deg, #3EDCFF, #6498FF, #5C71FF)',
          color: 'white',
          height: buttonHeight,
          borderRadius: buttonBorderRadius,
          '&:hover': {
            background: 'linear-gradient(-84deg, #35C6F0, #5B8FE0, #5261E0)', // Slightly darker gradient for hover
          },
        }}
      >
        {t('saveTheResultImage')}
      </Button>
      <Box height={'12px'} />
      {/* Retry Button */}
      <Button
        component={Link}
        to={gameResultQueue.game_prediction?.id ? retryUrl : '#'}
        variant="outlined"
        startIcon={<img src={Retry2Icon} style={iconStyle} />}
        sx={{
          fontWeight: 600,
          fontSize: isSmDown ? '16px' : '20px',
          height: buttonHeight,
          border: '1px solid #2C3887',
          color: '#2C3887',
          borderRadius: buttonBorderRadius,
          textDecoration: 'none', // Ensure no underline
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: '#E6E9F8', // Light background color for hover
            borderColor: '#2C3887', // Maintain border color
          },
        }}
      >
        {t('tryAgain')}
      </Button>

      <Box height={isSmDown ? '24px' : '32px'} />

      {/* Share Link Button */}
      <Button
        endIcon={<img src={Share2Icon} style={{ width: '20px', height: '20px' }} />}
        onClick={handleShare}
        sx={{
          fontWeight: 600,
          fontSize: isSmDown ? '14px' : '18px',
          backgroundColor: gameResultQueue.game_prediction?.game_prediction_group?.main_color,
          color: getContrastColor(gameResultQueue.game_prediction?.game_prediction_group?.main_color),
          height: buttonHeight,
          borderRadius: buttonBorderRadius,
          '&:hover': {
            backgroundColor: `${hexToRgba(gameResultQueue.game_prediction?.game_prediction_group?.main_color, 0.7)}`, // 70% opacity on hover
          },
        }}
      >
        {t('shareTheLinkWithFriend')}
      </Button>
      {/* Snackbar */}
      <SnackbarAlert open={openSnackbar} type={SnackbarAlertType.URL_COPY_SUCCESS} onClose={handleSnackbarClose} />
    </Box>
  );
};

export default ResultButtonGroup;
