import { Grid, Link, Typography, Container, Stack } from '@mui/material';

const Footer = () => {
  return (
    <Container>
      <Stack alignItems={'center'}>
        <Stack direction={'row'} spacing={2}>
          <Link target="_blank" href="/company/introduce" sx={{ textDecoration: 'none', zIndex: 1 }}>
            <Typography fontSize={14} color="darkgray" display="inline">
              Introduce
            </Typography>
          </Link>
          <Link target="_blank" href="/company/privacy-policy" sx={{ textDecoration: 'none', zIndex: 1 }}>
            <Typography fontSize={14} color="darkgray" display="inline">
              Privacy & Policy
            </Typography>
          </Link>
          <Link target="_blank" href="/company/terms-of-service" sx={{ textDecoration: 'none', zIndex: 1 }}>
            <Typography fontSize={14} color="darkgray" display="inline">
              Terms of Service
            </Typography>
          </Link>
        </Stack>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={5} lg={4} textAlign="center">
            {/* <img src={logoIcon} /> */}
            <Typography fontSize={16} color="textSecondary" mt={1} mb={4}>
              All rights reserved by ©PlayMong.
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};

export default Footer;
