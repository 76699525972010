import CryptoJS from 'crypto-js'; // Import crypto-js

/**
 * RGB 색상 값을 나타내는 인터페이스
 */
export interface RGB {
  r: number;
  g: number;
  b: number;
}

/**
 * HSL 색상 값을 나타내는 인터페이스
 */
export interface HSL {
  h: number; // 0 - 360
  s: number; // 0 - 100
  l: number; // 0 - 100
}

/**
 * 색상 객체를 나타내는 인터페이스
 */
export interface CorrespondingColors {
  mainColor: string; // 메인 색상 (Hex 형식)
  veryBrightColor: string; // 매우 밝은 색상 (Hex 형식)
  brightColor: string; // 밝은 색상 (Hex 형식)
  slightlyDarkColor: string; // 조금 어두운 색상 (Hex 형식)
  darkColor: string; // 어두운 색상 (Hex 형식)
  darkerColor: string; // 더 어두운 색상 (Hex 형식)
  gradientColors: string[]; // 그라데이션 색상 배열
}

/**
 * 주어진 Hex 색상 코드의 보색(complementary color)을 반환합니다.
 * @param hex - 3자리 또는 6자리의 Hex 색상 코드 (예: "#FFF" 또는 "#FFFFFF")
 * @returns 보색 Hex 색상 코드 (예: "#000000")
 */
const getComplementaryColor = (hex: string | null | undefined): string => {
  hex = hex ?? '';
  // '#' 문자를 제거하고 소문자로 변환
  let cleanHex = hex.replace('#', '').toLowerCase();

  // 3자리 Hex 코드를 6자리로 변환
  if (cleanHex.length === 3) {
    cleanHex = cleanHex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // 유효한 6자리 Hex 코드인지 확인
  if (!/^([0-9a-f]{6})$/.test(cleanHex)) {
    console.warn(`유효하지 않은 Hex 색상 코드입니다: ${hex}`);
    return '#000000'; // 기본값으로 검은색 반환
  }

  // 각 색상 채널의 값을 계산
  const r = 255 - parseInt(cleanHex.substring(0, 2), 16);
  const g = 255 - parseInt(cleanHex.substring(2, 4), 16);
  const b = 255 - parseInt(cleanHex.substring(4, 6), 16);

  // 보색을 Hex 코드로 변환
  const complementaryHex = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;

  return complementaryHex;
};

// Helper function to convert hex color to rgba
const hexToRgba = (hex: string | undefined | null, alpha: number) => {
  hex = hex ?? '';
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

/**
 * #111111과 #EEEEEE중에 잘 어울리는 색을 반환
 * @param {string} hexColor - The background color in hex format.
 * @returns {string} - Returns '#111111' or '#ffffff'.
 */
const getContrastColor = (hexColor: string | undefined | null) => {
  if (!hexColor) return '#111111'; // Default to dark if no color provided

  // Remove the hash symbol if present
  const color = hexColor.replace('#', '');

  // Parse the r, g, b values
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  // Calculate luminance using the formula
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return dark text for light backgrounds, and light text for dark backgrounds
  return luminance > 0.5 ? '#111111' : '#EEEEEE';
};

/**
 * Hex 색상 문자열을 RGB 객체로 변환합니다.
 * @param hex - Hex 색상 문자열 (예: "#5D87FF")
 * @returns RGB 객체
 */
function hexToRgb(hex: string): RGB {
  // 해시 기호 제거
  hex = hex.replace(/^#/, '');

  // 3자리 또는 6자리인지 확인
  if (hex.length !== 3 && hex.length !== 6) {
    throw new Error('Invalid hex color.');
  }

  let bigint: number;
  try {
    bigint = parseInt(hex, 16);
  } catch {
    throw new Error('Invalid hex color.');
  }

  let r: number, g: number, b: number;
  if (hex.length === 6) {
    r = (bigint >> 16) & 255;
    g = (bigint >> 8) & 255;
    b = bigint & 255;
  } else {
    // 3자리
    r = ((bigint >> 8) & 15) * 17;
    g = ((bigint >> 4) & 15) * 17;
    b = (bigint & 15) * 17;
  }
  return { r, g, b };
}

/**
 * RGB 객체를 HSL 객체로 변환합니다.
 * @param rgb - RGB 객체
 * @returns HSL 객체
 */
function rgbToHsl(rgb: RGB): HSL {
  let { r, g, b } = rgb;
  r /= 255;
  g /= 255;
  b /= 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h: number = 0,
    s: number = 0,
    l: number = (max + min) / 2;

  if (max !== min) {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = 0;
    }
    h *= 60;
  }

  return { h, s: s * 100, l: l * 100 };
}

/**
 * HSL 객체를 RGB 객체로 변환합니다.
 * @param hsl - HSL 객체
 * @returns RGB 객체
 */
function hslToRgb(hsl: HSL): RGB {
  let { h, s, l } = hsl;
  h /= 360;
  s /= 100;
  l /= 100;

  let r: number, g: number, b: number;

  if (s === 0) {
    r = g = b = l; // 무채색
  } else {
    const hue2rgb = (p: number, q: number, t: number): number => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    let q: number = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p: number = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
}

/**
 * RGB 객체를 Hex 색상 문자열로 변환합니다.
 * @param rgb - RGB 객체
 * @returns Hex 색상 문자열 (예: "#5D87FF")
 */
function rgbToHex(rgb: RGB): string {
  const toHex = (c: number): string => {
    let hex = c.toString(16).toUpperCase();
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${toHex(rgb.r)}${toHex(rgb.g)}${toHex(rgb.b)}`;
}

/**
 * RGB 객체를 RGBA 문자열로 변환합니다.
 * @param rgb - RGB 객체
 * @param alpha - 알파 값 (0 - 1)
 * @returns RGBA 문자열 (예: "rgba(244, 249, 254, 0.5)")
 */
function rgbToRgba(rgb: RGB, alpha: number): string {
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
}

/**
 * 주어진 MainColor를 기반으로 대응되는 색상들을 생성합니다.
 * @param mainColor - 메인 색상 (Hex 형식, 예: "#5D87FF")
 * @returns CorrespondingColors 객체
 */
function generateCorrespondingColors(mainColor: string | null | undefined): CorrespondingColors {
  mainColor = mainColor ?? '#FFFFFF'; // 기본 색상 설정

  // 유효한 HEX 색상인지 확인
  const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
  if (!hexRegex.test(mainColor)) {
    console.warn(`Invalid hex color: "${mainColor}". Falling back to default color.`);
    mainColor = '#CCCCCC'; // 유효하지 않은 경우 기본 연한 회색 사용
  }

  // MainColor를 RGB로 변환
  const rgb: RGB = hexToRgb(mainColor);

  // RGB를 HSL로 변환
  const hsl: HSL = rgbToHsl(rgb);

  // 매우 밝은 색상 생성
  const veryBrightHsl: HSL = {
    ...hsl,
    l: Math.min(hsl.l + 60, 98), // 밝기 60 증가, 최대 98로 제한
    s: Math.max(hsl.s - 20, 0), // 채도 20 감소, 최소 0으로 제한
  };
  const veryBrightRgb: RGB = hslToRgb(veryBrightHsl);
  const veryBrightColor: string = rgbToHex(veryBrightRgb); // Hex 형식으로 변환

  // 밝은 색상 생성
  const brightHsl: HSL = {
    ...hsl,
    l: Math.min(hsl.l + 40, 90), // 밝기 40 증가, 최대 90으로 제한
    s: Math.max(hsl.s - 10, 0), // 채도 10 감소, 최소 0으로 제한
  };
  const brightRgb: RGB = hslToRgb(brightHsl);
  const brightColor: string = rgbToHex(brightRgb); // Hex 형식으로 변환

  // 조금 어두운 색상 생성
  const slightlyDarkHsl: HSL = {
    ...hsl,
    l: Math.max(hsl.l - 10, 0), // 밝기 10 감소, 최소 0으로 제한
    s: Math.min(hsl.s + 5, 100), // 채도 5 증가, 최대 100으로 제한
  };
  const slightlyDarkRgb: RGB = hslToRgb(slightlyDarkHsl);
  const slightlyDarkColor: string = rgbToHex(slightlyDarkRgb); // Hex 형식으로 변환

  // 어두운 색상 생성
  const darkHsl: HSL = {
    ...hsl,
    l: Math.max(hsl.l - 30, 0), // 밝기 30 감소, 최소 0으로 제한
    s: Math.min(hsl.s + 10, 100), // 채도 10 증가, 최대 100으로 제한
  };
  const darkRgb: RGB = hslToRgb(darkHsl);
  const darkColor: string = rgbToHex(darkRgb); // Hex 형식으로 변환

  // 더 어두운 색상 생성
  const darkerHsl: HSL = {
    ...darkHsl,
    l: Math.max(darkHsl.l - 20, 0), // 밝기 20 감소, 최소 0으로 제한
    s: Math.min(darkHsl.s + 10, 100), // 채도 10 증가, 최대 100으로 제한
  };
  const darkerRgb: RGB = hslToRgb(darkerHsl);
  const darkerColor: string = rgbToHex(darkerRgb); // Hex 형식으로 변환

  // 그라데이션 색상 생성 (예: "#5C71FF, #6498FF, #3EDCFF")
  // Hue를 -10, 0, +10만큼 조정하여 생성
  let gradientColors: string[] = [];
  const hueOffsets: number[] = [-10, 0, 10];
  const saturationOffsets: number[] = [-10, 0, 10]; // 채도 약간 조정

  hueOffsets.forEach((offset, index) => {
    let newH = (hsl.h + offset) % 360;
    if (newH < 0) newH += 360;
    let newS = Math.min(Math.max(hsl.s + saturationOffsets[index], 0), 100); // 채도(s)를 조정
    let gradientHsl: HSL = { h: newH, s: newS, l: hsl.l };
    let gradientRgb: RGB = hslToRgb(gradientHsl);
    gradientColors.push(rgbToHex(gradientRgb));
  });

  return {
    mainColor,
    veryBrightColor, // 매우 밝은 색상
    brightColor, // 밝은 색상
    slightlyDarkColor, // 조금 어두운 색상
    darkColor, // 어두운 색상
    darkerColor, // 더 어두운 색상
    gradientColors, // 그라데이션 색상 배열
  };
}

/**
 * 주어진 배경 색상 위에 흰색을 특정 투명도로 오버레이한 최종 색상을 반환합니다.
 * @param hexColor - 배경 색상 (예: '#RRGGBB' 또는 '#RGB')
 * @param alpha - 흰색 오버레이의 투명도 값 (0 ~ 1)
 * @returns 최종 혼합된 색상 (예: '#RRGGBB')
 */
function blendWhiteOverHex(hexColor: string | null | undefined, alpha: number): string {
  hexColor = hexColor ?? '';
  // '#' 제거
  let hex = hexColor.replace(/^#/, '');

  // 3자리 Hex 코드를 6자리로 변환
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((c) => c + c)
      .join('');
  }

  if (hex.length !== 6) {
    // throw new Error('유효하지 않은 Hex 색상 형식입니다. 예: "#RRGGBB"');
    // console.log('유효하지 않은 Hex 색상 형식입니다. 예: "#RRGGBB"');
    hex = 'FFFFFF';
  }

  // Hex를 RGB로 변환
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // 흰색과의 혼합 계산 (흰색 RGB: 255, 255, 255)
  const blendedR = Math.round(255 * alpha + r * (1 - alpha));
  const blendedG = Math.round(255 * alpha + g * (1 - alpha));
  const blendedB = Math.round(255 * alpha + b * (1 - alpha));

  // RGB를 Hex로 변환
  const toHex = (c: number) => c.toString(16).padStart(2, '0');

  return `#${toHex(blendedR)}${toHex(blendedG)}${toHex(blendedB)}`;
}

function getContrastingTextColor(mainColor: string | null | undefined): string {
  // 유효한 mainColor인지 확인
  if (!mainColor || !/^#([0-9A-Fa-f]{3}){1,2}$/.test(mainColor)) {
    console.warn('Invalid mainColor, using default color #FFFFFF');
    return '#FFFFFF'; // 기본값으로 흰색 반환
  }

  // HexColor를 RGB로 변환
  const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
    const normalizedHex = hex.replace(/^#/, '');
    const bigint = parseInt(
      normalizedHex.length === 3
        ? normalizedHex
            .split('')
            .map((c) => c + c)
            .join('') // #RGB를 #RRGGBB로 확장
        : normalizedHex,
      16,
    );
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  // RGB 값을 입력받아 상대 밝기 계산
  const calculateLuminance = ({ r, g, b }: { r: number; g: number; b: number }): number => {
    const [rr, gg, bb] = [r, g, b].map((channel) => {
      const normalized = channel / 255;
      return normalized <= 0.03928 ? normalized / 12.92 : Math.pow((normalized + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * rr + 0.7152 * gg + 0.0722 * bb; // 밝기 계산 공식
  };

  const rgb = hexToRgb(mainColor);
  const luminance = calculateLuminance(rgb);

  // 대비 기준 색상 밝기 (검정과 흰색의 상대 밝기)
  const blackLuminance = 0.0;
  const whiteLuminance = 1.0;

  const contrastWithBlack = (luminance + 0.05) / (blackLuminance + 0.05);
  const contrastWithWhite = (whiteLuminance + 0.05) / (luminance + 0.05);

  // WCAG 가이드라인에 따라 대비가 높은 색상 선택
  return contrastWithBlack > contrastWithWhite ? '#000000' : '#FFFFFF';
}

/**
 * Calculates the relative luminance of a color.
 * @param rgb - An object with r, g, b values (0-255).
 * @returns The relative luminance.
 */
export const getRelativeLuminance = (rgb: { r: number; g: number; b: number }): number => {
  const { r, g, b } = rgb;
  const [RsRGB, GsRGB, BsRGB] = [r, g, b].map((value) => {
    const srgb = value / 255;
    return srgb <= 0.03928 ? srgb / 12.92 : Math.pow((srgb + 0.055) / 1.055, 2.4);
  });

  return 0.2126 * RsRGB + 0.7152 * GsRGB + 0.0722 * BsRGB;
};

/**
 * Determines the most suitable text colors (main and sub) for a given background color,
 * considering whether the box is selected or not.
 * @param backgroundColor The background color in hexadecimal format (e.g., "#FFFFFF").
 * @param isSelected A boolean indicating whether the box is selected.
 * @returns An object with mainTextColor (#111111 or #EEEEEE) and subTextColor (#777777 or #888888).
 */
function getBoxTextColors(
  backgroundColor: string,
  isSelected: boolean,
): {
  mainTextColor: string;
  subTextColor: string;
} {
  const mainSelectedHexColor = '#111111';
  const subSelectedHexColor = '#777777';
  const mainUnselectedHexColor = '#EEEEEE';
  const subUnselectedHexColor = '#CCCCCC';
  // If not selected, return default colors
  if (!isSelected) {
    return {
      mainTextColor: mainSelectedHexColor,
      subTextColor: subSelectedHexColor,
    };
  }

  // Convert the background color to RGB
  const rgb = hexToRgb(backgroundColor);
  if (!rgb) throw new Error('Invalid background color format.');

  // Calculate the luminance of the background color
  const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;

  // Determine mainTextColor based on luminance
  const mainTextColor = luminance > 0.5 ? mainSelectedHexColor : mainUnselectedHexColor;

  // Determine subTextColor based on luminance
  const subTextColor = luminance > 0.5 ? subSelectedHexColor : subUnselectedHexColor;

  return { mainTextColor, subTextColor };
}

export { getComplementaryColor, hexToRgb, hexToRgba, getContrastColor, generateCorrespondingColors, blendWhiteOverHex, getContrastingTextColor, getBoxTextColors };
