import { ApiNormalResponse } from 'src/types/apps/common';
import { GameSpeakerType } from 'src/types/apps/game/type';
import { GamePredictionCountType, GamePredictionFilterType, GamePredictionResultQueueType, GamePredictionResultType, GamePredictionType } from 'src/types/apps/game/prediction/type';
import { useSelector } from 'src/store/Store';
const baseUrl = import.meta.env.VITE_BASE_URL ?? '';
const defaultLanguageCode = 'en-US';
/**
 * Games 반환
 * @param {string | null} languageCode - language_code
 * @param {GamePredictionFilterType} filter - filter
 * @returns {Promise<GamePredictionCountType[]>} - Game 목록
 */
const fetchGamesFromService = async (languageCode: string | null, filter: GamePredictionFilterType): Promise<GamePredictionCountType[]> => {
  try {
    const params = new URLSearchParams({
      language_code: languageCode ?? defaultLanguageCode,
      image_count: filter.imageCount,
      sort: filter.sort,
      duration: filter.duration,
      status: import.meta.env.VITE_STATUS,
    });
    const requestUrl = `${baseUrl}/v1/game/prediction/list?${params.toString()}`;

    const response = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        apikey: import.meta.env.VITE_MONGMONG_API_KEY,
      },
    });

    if (response.ok) {
      const responseData: ApiNormalResponse = await response.json();
      console.log(`data = ${responseData.body.game_prediction_counts}`);
      return responseData.body.game_prediction_counts ?? [];
    } else {
      return [];
    }
  } catch (err) {
    console.error('Failed to fetch games:', err);
    throw err;
  }
};

/**
 * Game 반환
 * @param {number} gameId - Game의 id
 * @returns {Promise<GamePredictionType | null>} - Game 목록
 */
const getGameFromService = async (gameId: number): Promise<GamePredictionType | null> => {
  try {
    const requestUrl = `${baseUrl}/v1/game/prediction/${gameId}`;

    const response = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        apikey: import.meta.env.VITE_MONGMONG_API_KEY,
      },
    });
    if (response.ok) {
      const responseData: ApiNormalResponse = await response.json();
      return responseData.body.game_prediction ?? null;
    } else {
      return null;
    }
  } catch (err) {
    console.error('Failed to fetch games:', err);
    throw err;
  }
};

/**
 * Game의 Speaker들을 반환
 * @param {string | null} languageCode - language_code
 * @returns {Promise<GameSpeakerType[]>} - Speaker 목록
 */
const getSpeakersFromService = async (languageCode: string | null): Promise<GameSpeakerType[]> => {
  try {
    const statusCode = import.meta.env.VITE_STATUS;
    const requestUrl = `${baseUrl}/v1/game/speaker/list?language_code=${encodeURIComponent(languageCode ?? '')}&statusCode=Y`;
    const response = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        apikey: import.meta.env.VITE_MONGMONG_API_KEY,
      },
    });
    if (response.ok) {
      const responseData: ApiNormalResponse = await response.json();
      return responseData.body.speakers ?? [];
    } else {
      return [];
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Game의 결과를 생성
 * @param {number | null} gameId - Game의 id
 * @param {number | null} gameGroupId - GameGroup의 id
 * @param {number | null} speakerId - Speaker의 id
 * @param {string | null} resultTextLength - Result Text의 길이
 * @param {string[]} imageUrls - 선택한 이미지들
 * @param {string} languageCode - 이용자의 LanguageCode
 * @returns {Promise<string>} - 임시 결과 uuid 값
 */
const generateGameResultFromService = async (
  gameId: number | null,
  gameGroupId: number | null,
  speakerId: number | null,
  resultTextLength: string | null,
  imageUrls: string[],
  languageCode: string,
): Promise<string> => {
  try {
    const requestUrl = `${baseUrl}/v1/game/prediction/result/generate`;
    const response = await fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        apikey: import.meta.env.VITE_MONGMONG_API_KEY,
      },
      body: JSON.stringify({
        gameId,
        gameGroupId,
        speakerId,
        resultTextLength,
        imageUrls,
        languageCode,
      }),
    });
    if (response.ok) {
      const responseData: ApiNormalResponse = await response.json();

      // 성공후, 결과 uuid 반환
      return responseData.body.game_prediction_result_uuid ?? '';
    } else {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }
  } catch (err) {
    console.error('Failed to fetch game result:', err);
    throw err;
  }
};

/**
 * Game의 결과를 생성
 * @param {number | null} gameResultTemporaryId - GameResult의 temporary id
 * @returns {Promise<GamePredictionResultQueueType | null>} - 임시 결과 uuid 값
 */
const getGameResultFromService = async (gameResultTemporaryId: number | null): Promise<GamePredictionResultQueueType | null> => {
  try {
    const requestUrl = `${baseUrl}/v1/game/prediction/result?game_prediction_result_temporary_id=${gameResultTemporaryId}`;
    const response = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        apikey: import.meta.env.VITE_MONGMONG_API_KEY,
      },
    });
    if (response.ok) {
      const responseData: ApiNormalResponse = await response.json();
      // 성공 여부 반환
      if (responseData.body.game_prediction_result_queue) {
        return responseData.body.game_prediction_result_queue;
      } else {
        return null;
      }
    } else {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }
  } catch (err) {
    console.error('Failed to fetch game result:', err);
    throw err;
  }
};

/**
 * Game의 ((공유 수) or (Play 수)) 올려주기
 * @param {IncreaseGameShareCountFromServiceType} type - 올리는 값의 type
 * @param {number | null} gameGroupId - GameGroup의 id
 * @param {number | null} gameId - Game의 id
 * @returns {Promise<boolean>} - 성공 여부 반환
 */
export enum IncreaseGameShareCountFromServiceType {
  play = 'play',
  share = 'share',
}
const increaseGameShareCountFromService = async (type: IncreaseGameShareCountFromServiceType, gameGroupId: number | null, gameId: number | null): Promise<boolean> => {
  try {
    const requestUrl = `${baseUrl}/v1/game/prediction/${gameId}/count/increment`;
    const response = await fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        apikey: import.meta.env.VITE_MONGMONG_API_KEY,
      },
      body: JSON.stringify({ type, gameGroupId }),
    });

    if (response.ok) {
      const responseData: ApiNormalResponse = await response.json();
      return responseData.header.code == 2000;
    } else {
      return false;
    }
  } catch (err) {
    throw err;
  }
};

export { fetchGamesFromService, getGameFromService, getGameResultFromService, getSpeakersFromService, generateGameResultFromService, increaseGameShareCountFromService };
