// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import GamePredictionResultContainer from 'src/views/service/game/prediction/GamePredictionResultContainer';

/* ***Layout**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Service***** */
const GamePredictionListPage = Loadable(lazy(() => import('../views/service/game/prediction/GamePredictionListPage')));
const GamePredictionContainer = Loadable(lazy(() => import('../views/service/game/prediction/GamePredictionContainer')));

/* ****Contact***** */
const ContactUs = Loadable(lazy(() => import('../views/service/contact/ContactUs')));
const NewContentSuggestions = Loadable(lazy(() => import('../views/service/contact/NewContentSuggestions')));

/* ****Company***** */
const Introduce = Loadable(lazy(() => import('../views/service/company/Introduce')));
const PrivacyPolicy = Loadable(lazy(() => import('../views/service/company/PrivacyPolicy')));
const TermsOfService = Loadable(lazy(() => import('../views/service/company/TermsOfService')));

/* ****Error***** */
const Error = Loadable(lazy(() => import('../views/error/Error404')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/service/game/prediction" /> },
      { path: '/service/game/prediction', element: <GamePredictionListPage /> },
      { path: '/service/game/prediction/result/:id', element: <GamePredictionResultContainer /> },
      { path: '*', element: <Navigate to="/service/game/prediction" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      // { path: '/auth/404', element: <Error /> },
      { path: '/service/game/prediction/detail/:encryptId', element: <GamePredictionContainer /> },
      { path: '/contact/contact-us', element: <ContactUs /> },
      { path: '/contact/new-content-suggestions', element: <NewContentSuggestions /> },
      { path: '/company/introduce', element: <Introduce /> },
      { path: '/company/privacy-policy', element: <PrivacyPolicy /> },
      { path: '/company/terms-of-service', element: <TermsOfService /> },
      // { path: '*', element: <Navigate to="/auth/404" /> },  // 없는페이지 들어왔을떄 작동
    ],
  },
];

export default Router;
