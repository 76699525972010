import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { AppState } from 'src/store/Store';

export enum GameType {
  Prediction = 'Prediction',
}

interface VisibleData {
  isAdVisible: boolean;
  gameType: GameType | null;
  gameId: string | null;
}

interface StateType {
  visibleData: VisibleData;
}

const initialState: StateType = {
  visibleData: { isAdVisible: false, gameType: GameType.Prediction, gameId: null },
};

export const AdSlice = createSlice({
  name: 'ad',
  initialState,
  reducers: {
    setAdVisible(state, action) {
      state.visibleData = action.payload;
    },
  },
});
export const { setAdVisible } = AdSlice.actions;

export const setAd =
  (visibleData: VisibleData) => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      dispatch(setAdVisible(visibleData));
    } catch (error) {
      console.log(error);
    }
  };

export default AdSlice.reducer;
