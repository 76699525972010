import { Grid, Box, CircularProgress, Typography } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import { useParams } from 'react-router-dom';
import { GamePredictionResultQueueType, GamePredictionResultType } from 'src/types/apps/game/prediction/type';
import { useEffect, useState, useRef } from 'react';
import { getGameResultFromService } from 'src/services/Game/Prediction/service';
import { useTranslation } from 'react-i18next';
import Result from 'src/components/apps/game/game/prediction/result/Result';
import { XL_WIDTH_PERCENT, XS_WIDTH_VALUE, SM_WIDTH_DEFAULT } from 'src/constants/layoutConstants';
import ImageLoaderAnimation from 'src/assets/images/etc/image_loader.json';
import Lottie from 'react-lottie-player';

const MAX_ATTEMPTS = 100;
const POLLING_INTERVAL = 5000; // 5초

const GamePredictionResultContainer = () => {
  const params: any = useParams();
  const [gameResultQueue, setGameResultQueue] = useState<GamePredictionResultQueueType | null>(null);
  const [attemptCount, setAttemptCount] = useState(0);
  const { t } = useTranslation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // 인터벌 참조 추가

  // 문서 제목 초기화
  useEffect(() => {
    if (gameResultQueue?.game_prediction_result?.result_summary) {
      const documentTitle = `${t('gameResult')} | ${gameResultQueue.game_prediction_result.result_summary}`;
      document.title = documentTitle;
    } else {
      document.title = t('gameResult') || 'Game Result';
    }
  }, [gameResultQueue, t]);

  useEffect(() => {
    let isMounted = true;

    const fetchGameResult = async () => {
      try {
        const gameId = params.id;
        if (gameId) {
          const resultQueue = await getGameResultFromService(gameId);
          console.log('@1');
          if (isMounted) {
            console.log('@2', resultQueue);
            if (resultQueue?.game_prediction_result) {
              console.log('@3');
              setGameResultQueue(resultQueue);
              // 결과를 받았으므로 인터벌 정리
              if (intervalRef.current) {
                clearInterval(intervalRef.current);
              }
            } else {
              // 최대 시도 횟수에 도달했는지 확인
              setAttemptCount((prevCount) => {
                const newCount = prevCount + 1;
                if (newCount >= MAX_ATTEMPTS && intervalRef.current) {
                  clearInterval(intervalRef.current);
                }
                return newCount;
              });
            }
          }
        }
      } catch (error) {
        console.error('Error fetching game result:', error);
        // 에러 발생 시에도 인터벌 정리할 수 있습니다.
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    };

    // 초기 호출
    fetchGameResult();

    // 3초마다 호출되는 인터벌 설정
    intervalRef.current = setInterval(fetchGameResult, POLLING_INTERVAL);

    return () => {
      isMounted = false;
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [params.id]);

  return (
    <PageContainer title={`${t('gameResult')} | ${gameResultQueue?.game_prediction_result?.result_summary}`} description={gameResultQueue?.game_prediction_result?.result_detail ?? ''}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box
          sx={{
            width: {
              xs: XS_WIDTH_VALUE(0),
              sm: SM_WIDTH_DEFAULT,
            },
          }}
        >
          <Result gameResultQueue={gameResultQueue} />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default GamePredictionResultContainer;
