import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import { fetchSpeakers } from './game/prediction/SpeakerSlice';

interface StateType {
  languageCode: string;
}

const initialState: StateType = {
  languageCode: localStorage.getItem('language_code') ?? 'en',
};

export const LanguageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.languageCode = action.payload;
    },
  },
});

export const { setLanguage } = LanguageSlice.actions;

export const setLanguageCode = (languageCode: string) => async (dispatch: AppDispatch, getState: () => AppState) => {
  try {
    // Set language code in localStorage
    localStorage.setItem('language_code', languageCode);
    // Dispatch action to update Redux store
    dispatch(setLanguage(languageCode));
    // Dispatch fetchSpeakers to update speakers based on the new language
    dispatch(fetchSpeakers());
  } catch (error) {
    console.log('Error setting language code:', error);
  }
};

export default LanguageSlice.reducer;
