// src/apps/game/prediction/SpeakerSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppState, useSelector } from 'src/store/Store';
import { fetchGamesFromService, getSpeakersFromService } from 'src/services/Game/Prediction/service';
import { GameSpeakerType } from 'src/types/apps/game/type';

interface StateType {
  speakers: GameSpeakerType[];
  error: string;
}

const initialState: StateType = {
  speakers: [],
  error: '',
};

export const SpeakerSlice = createSlice({
  name: 'speaker',
  initialState,
  reducers: {
    hasError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    getSpeakers(state, action: PayloadAction<GameSpeakerType[]>) {
      state.speakers = action.payload;
    },
  },
});

export const { hasError, getSpeakers } = SpeakerSlice.actions;

// Thunk action to fetch speakers
export const fetchSpeakers = () => async (dispatch: AppDispatch, getState: () => AppState) => {
  try {
    const languageCode = getState().language.languageCode;
    const speakers = await getSpeakersFromService(languageCode);
    dispatch(getSpeakers(speakers));
  } catch (error) {
    // Ensure error is a string
    dispatch(hasError(error instanceof Error ? error.message : 'Unknown error'));
  }
};

export default SpeakerSlice.reducer;
