import { useTranslation } from 'react-i18next';
import { DefaultLanguageCode, LanguageCodes } from 'src/constants/languages/Language'; // 지원되는 언어 목록
import i18n from 'src/utils/i18n';
import { useDispatch } from 'src/store/Store';
import { setLanguage, setLanguageCode } from 'src/store/apps/LanguageSlice';
import store from 'src/store/Store'; // Import the Redux store directly

/**
 * 최초 LanguageCode 반환
 * @returns {string} - 초기 언어 반환
 */
const initLanguageandGetLanguageCode = (): string => {
  try {
    const storedLanguageCode = localStorage.getItem('language_code');
    const systemLanguageCode = navigator.language.split('-')[0]; // 'en-US' -> 'en'

    let languageCode = 'en';
    if (storedLanguageCode) {
      // 로컬스토리지에 저장된 언어가 있을 경우, 반환
      languageCode = storedLanguageCode;
    } else if (!LanguageCodes.includes(systemLanguageCode)) {
      // 이용자 anguageCode가 지원 목록에 없을 경우, 'en' 반환
      languageCode = DefaultLanguageCode;
    } else {
      // 이용자 LanguageCode 반환
      languageCode = systemLanguageCode;
    }

    localStorage.setItem('language_code', languageCode);
    store.dispatch(setLanguageCode(languageCode));
    return languageCode;
  } catch (err) {
    console.error(`Failed: ${err}`);
    throw err;
  }
};

/**
 * 이용자 LanguageCode 변경
 * @param {string} languageCode - 변경할 이용자의 LanguageCode
 */
const setLanguageFromService = async (languageCode: string) => {
  try {
    // 1. 언어를 로컬스토리지에 저장
    localStorage.setItem('language_code', languageCode); // 캐시 저장

    // 2. 언어 변경 (비동기 작업 대기)
    await i18n.changeLanguage(languageCode);
    // 3. 상태 업데이트
    //  setCurrentLanguage(option.language_code); // 현재 언어 상태 업데이트
    //  // 4. Refresh
    //  dispatch(setLanguage(option.language_code));

    // // 1. 로컬스토리지에 저장된 언어가 있을 경우, 해당 LanguageCode 반환하고 종료
    // const storedLanguageCode = localStorage.getItem('language_code');
    // if (storedLanguageCode) {
    //   return storedLanguageCode;
    // }
    // // 2. 이용자 LanguageCode를 가져오기
    // const systemLanguageCode = navigator.language.split('-')[0]; // 'en-US' -> 'en'
    // // 3. (2번)의 LanguageCode가 지원 목록에 없을 경우, 'en' 반환
    // if (!LanguageCodes.includes(systemLanguageCode)) {
    //   localStorage.setItem('language_code', DefaultLanguageCode);
    //   return DefaultLanguageCode;
    // }
    // // 4. (2번) 결과 반환
    // localStorage.setItem('language_code', systemLanguageCode);
    // return systemLanguageCode;
  } catch (err) {
    console.error(`Failed: ${err}`);
    throw err;
  }
};

export { initLanguageandGetLanguageCode, setLanguageFromService };
