// PageContainer.tsx
import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  description?: string;
  children: JSX.Element | JSX.Element[];
  title?: string;
  ogImage?: string;
  ogUrl?: string;
  ogType?: string;
};

const PageContainer = ({ title, description, children, ogImage, ogUrl, ogType = 'website' }: Props) => (
  <div>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Open Graph Tags */}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      <meta property="og:type" content={ogType} />
    </Helmet>
    {children}
  </div>
);

export default PageContainer;
