const loadScriptFromAdsense = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    // 이미 스크립트가 로드된 경우
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script ${src}`));
    document.body.appendChild(script);
  });
};
export { loadScriptFromAdsense };
