import { useEffect, useRef } from 'react';
import { loadScriptFromAdsense } from 'src/utils/adsenseUtils';

interface UseIMAProps {
  adTagUrl: string;
  onAdError?: (event: google.ima.AdErrorEvent) => void;
  onAdsManagerLoaded?: (adsManager: google.ima.AdsManager) => void;
  onAdComplete?: () => void; // 광고가 완료되었을 때 호출될 콜백
}

const useIMA = ({ adTagUrl, onAdError, onAdsManagerLoaded, onAdComplete }: UseIMAProps) => {
  const adContainerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const initIMA = async () => {
      try {
        // IMA SDK 스크립트 로드
        await loadScriptFromAdsense('https://imasdk.googleapis.com/js/sdkloader/ima3.js');
        console.log('IMA SDK loaded');

        if (!window.google || !window.google.ima) {
          console.error('IMA SDK not available after script load');
          return;
        }

        const adContainer = adContainerRef.current;
        const videoContent = videoRef.current;

        if (!adContainer || !videoContent) {
          // console.error('Ad container or video element not found');
          return;
        }

        // IMA SDK 초기화
        const adDisplayContainer = new window.google.ima.AdDisplayContainer(
          adContainer,
          videoContent,
        );
        const adsLoader = new window.google.ima.AdsLoader(adDisplayContainer);

        // 광고 요청 설정
        const adsRequest = new window.google.ima.AdsRequest();
        adsRequest.adTagUrl = adTagUrl;

        // 광고 슬롯 크기 설정
        adsRequest.linearAdSlotWidth = adContainer.clientWidth;
        adsRequest.linearAdSlotHeight = adContainer.clientHeight;
        adsRequest.nonLinearAdSlotWidth = adContainer.clientWidth;
        adsRequest.nonLinearAdSlotHeight = adContainer.clientHeight / 3;

        // 광고 로더 이벤트 리스너 추가
        adsLoader.addEventListener(
          window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
          (adsManagerLoadedEvent: google.ima.AdsManagerLoadedEvent) => {
            const adsManager = adsManagerLoadedEvent.getAdsManager(videoContent);

            // AdsManager 이벤트 리스너 추가
            if (onAdError) {
              adsManager.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, onAdError);
            }

            adsManager.addEventListener(
              window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
              () => videoContent.pause(),
            );
            adsManager.addEventListener(
              window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
              () => videoContent.play(),
            );

            // 추가적인 이벤트 리스너 (광고 완료 시 콜백 처리)
            adsManager.addEventListener(window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED, () => {
              console.log('All ads completed');
              if (onAdComplete) {
                onAdComplete(); // 광고 완료 시 호출
              }
            });
            adsManager.addEventListener(window.google.ima.AdEvent.Type.STARTED, () => {
              console.log('Ad started');
            });
            adsManager.addEventListener(window.google.ima.AdEvent.Type.COMPLETE, () => {
              console.log('Ad completed');
            });

            try {
              adsManager.init(
                adContainer.clientWidth,
                adContainer.clientHeight,
                window.google.ima.ViewMode.NORMAL,
              );
              adsManager.start();
              console.log('AdsManager started');

              if (onAdsManagerLoaded) {
                onAdsManagerLoaded(adsManager);
              }
            } catch (adError) {
              console.error('AdsManager could not be started', adError);
              adsManager.destroy();
            }
          },
        );

        adsLoader.addEventListener(
          window.google.ima.AdErrorEvent.Type.AD_ERROR,
          (adErrorEvent: google.ima.AdErrorEvent) => {
            console.error('Ad error:', adErrorEvent.getError());
            if (adsLoader) {
              adsLoader.destroy();
            }

            if (onAdError) {
              onAdError(adErrorEvent);
            }
          },
        );

        // 광고 컨테이너 초기화 및 광고 요청
        adDisplayContainer.initialize();
        adsLoader.requestAds(adsRequest);

        // 컴포넌트 언마운트 시 리소스 정리
        return () => {
          adsLoader.destroy();
        };
      } catch (error) {
        console.error('Failed to load IMA SDK script:', error);
      }
    };

    initIMA();
  }, [adTagUrl, onAdError, onAdsManagerLoaded, onAdComplete]);

  return { adContainerRef, videoRef };
};

export default useIMA;
