import { useEffect } from 'react';
import { CssBaseline, Box, ThemeProvider, useMediaQuery } from '@mui/material';
import { useRoutes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'src/store/Store';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { AppState } from './store/Store';
import useIMA from './hooks/useIma';
import { GameType, setAd } from 'src/store/apps/ad/AdSlice';
import './index.css';

function App() {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const dispatch = useDispatch();
  const customizer = useSelector((state: AppState) => state.customizer);
  const { isAdVisible, gameType, gameId } = useSelector((state: AppState) => state.ad.visibleData);
  const navigate = useNavigate();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md')); // 화면 크기 감지

  const { adContainerRef, videoRef } = useIMA({
    adTagUrl:
      'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinearvpaid2js&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=',
    onAdError: (error) => {
      console.error('Custom Ad Error Handler:', error);
    },
    onAdsManagerLoaded: (adsManager) => {
      console.log('Custom AdsManager Loaded:', adsManager);
    },
    onAdComplete: () => {
      switch (gameType) {
        case GameType.Prediction:
          dispatch(setAd({ isAdVisible: false, gameType: null, gameId: null }));
          navigate(`/service/game/prediction/result/${gameId}`);
          break;
        case null:
          break;
      }
    },
  });

  useEffect(() => {
    if (isAdVisible) {
      //
    }
  }, [isAdVisible]);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        {/* Ad Container */}
        {isAdVisible ? (
          isMdDown ? (
            // 모바일일때 (데스크탑에서 작동 안함)
            <Box
              ref={adContainerRef}
              bgcolor={'rgba(0, 0, 0, 0.8)'} // 반투명 배경
              sx={{
                position: 'fixed', // 고정 포지셔닝을 유지
                top: 0,
                left: 0,
                width: '100dvw',
                height: '100dvh', // 전체 뷰포트 높이
                zIndex: 1300,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden', // 스크롤바 숨김
                // border: '2px solid red',
              }}
            >
              <video
                ref={videoRef}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain', // 화면 넘침 방지
                }}
                playsInline
                autoPlay
                muted
                controls
              />
            </Box>
          ) : (
            // 데스크탑일때 (모바일에서 작동 안함)
            <Box
              ref={adContainerRef}
              bgcolor={'rgba(0, 0, 0, 0.8)'} // 반투명 배경
              sx={{
                position: 'fixed', // 고정 포지셔닝
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh', // 전체 뷰포트 높이
                zIndex: 1300,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden', // 스크롤바 숨김
              }}
            >
              <video
                ref={videoRef}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain', // 화면 넘침 방지
                }}
                playsInline
                autoPlay
                muted
                controls
              />
            </Box>
          )
        ) : (
          <ScrollToTop>{routing}</ScrollToTop>
        )}
      </RTL>
    </ThemeProvider>
  );
}

export default App;
