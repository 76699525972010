// src/utils/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DefaultLanguageCode } from '../constants/languages/Language';
import { initLanguageandGetLanguageCode } from 'src/services/Language/service';

// 리소스 타입 정의
type Resources = {
  [key: string]: {
    translation: Record<string, string>;
  };
};

// 번역 리소스 동적으로 로드하는 함수 (initialLanguageCode만 로드)
const loadResources = async (lang: string): Promise<Resources> => {
  const resources: Resources = {};

  try {
    const translation = await import(`src/languages/${lang}.json`);
    resources[lang] = { translation: translation.default };
  } catch (error) {
    console.warn(`번역 파일을 불러올 수 없습니다: ${lang}`);
  }

  return resources;
};

// i18n 초기화 함수
export const initializeI18n = async () => {
  try {
    // 1. 초기 언어 설정을 가져옴
    const initialLanguageCode = await initLanguageandGetLanguageCode();

    // 2. 초기 언어 코드에 해당하는 리소스를 불러옴
    const resources = await loadResources(initialLanguageCode);

    // 3. i18n 초기화
    await i18n
      .use(initReactI18next) // react-i18next와 i18n 연결
      .init({
        resources, // 초기 언어의 리소스만 로드됨
        lng: initialLanguageCode, // 초기 언어 설정
        fallbackLng: DefaultLanguageCode, // 지원되지 않는 언어일 경우 사용할 기본 언어
        interpolation: {
          escapeValue: false, // XSS 방지를 위해 HTML escape 적용 안 함
        },
      });
  } catch (err) {
    console.error('i18n 초기화 실패:', err);
    throw err; // 호출한 쪽에서 오류를 처리할 수 있도록 던짐
  }
};

export default i18n;
