import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Box, Typography, IconButton, Stack, Chip, useTheme, useMediaQuery, alpha, Skeleton } from '@mui/material';
import { toBlob, toPng } from 'html-to-image';
import { useTranslation } from 'react-i18next';
import desktopBackground from 'src/assets/images/game/prediction/game_background_desktop.svg';
import DownloadIcon from 'src/assets/images/etc/download.svg';
import { GamePredictionResultQueueType } from 'src/types/apps/game/prediction/type';
import { removeEmojis, parseStringToArray } from 'src/utils/utils';
import { generateCorrespondingColors, getContrastingTextColor } from 'src/utils/colorUtils';
import { FONT_CSS_FOR_HTML_TO_IMAGE } from 'src/constants/fontConstants';

export interface ResultSummaryCardHandle {
  handleDownload: () => void;
  componentRef: HTMLDivElement | null;
}

interface Props {
  gameResultQueue: GamePredictionResultQueueType | null;
  onHeightChange?: (height: number) => void;
}

const ResultSummaryCard = forwardRef<ResultSummaryCardHandle, Props>(({ gameResultQueue, onHeightChange }, ref) => {
  const { t } = useTranslation();
  const componentRef = useRef<HTMLDivElement>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const colors = generateCorrespondingColors(gameResultQueue?.game_prediction?.game_prediction_group?.main_color);

  useImperativeHandle(ref, () => ({
    handleDownload,
    componentRef: componentRef.current!,
  }));

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const keywordList = parseStringToArray(gameResultQueue?.game_prediction_result?.keyword_list);

  // 두 번째 이미지가 있는지 확인
  const hasSecondImage = Boolean(gameResultQueue?.game_prediction_result?.image_url_2);
  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  // 모든 이미지가 로드될 때까지 대기하는 함수
  const waitForImagesToLoad = () => {
    const images = componentRef.current?.getElementsByTagName('img') || [];
    const promises = Array.from(images).map((img) => {
      if (img.complete) return Promise.resolve();
      return new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });
    });
    return Promise.all(promises);
  };

  const handleDownload = async () => {
    if (!componentRef.current) return;
    setIsDownloading(true);

    try {
      // 모든 폰트가 로드될 때까지 대기
      await document.fonts.ready;

      // 모든 이미지가 로드될 때까지 대기
      await waitForImagesToLoad();

      // 컴포넌트에서 Blob 생성
      let blob = new Blob();
      for (let i = 0; i < 4; i++) {
        const newBlob = await toBlob(componentRef.current, {
          cacheBust: true,
          backgroundColor: 'rgba(0, 0, 0, 0)', // 투명 배경
          pixelRatio: 3,
          fontEmbedCSS: FONT_CSS_FOR_HTML_TO_IMAGE,
        });

        if (newBlob) {
          blob = newBlob;
        }
      }

      // Blob에서 File 생성
      const fileNameWithoutExtensionType = `${gameResultQueue?.game_prediction?.title_summary}_${t('summary')}_${Date.now()}`;
      const fileName = `${fileNameWithoutExtensionType}.png`;

      if (isMobileDevice()) {
        // 모바일 장치인 경우
        const file = new File([blob], fileName, { type: blob.type });
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          try {
            await navigator.share({
              title: gameResultQueue?.game_prediction?.title_summary ?? '이미지 공유',
              text: fileNameWithoutExtensionType,
              files: [file],
            });
            console.log('이미지가 성공적으로 공유되었습니다.');
          } catch (shareError) {
            console.error('이미지 공유에 실패했습니다.', shareError);
            // 공유에 실패하면 다운로드 시도
            fallbackDownload(blob, fileName);
          }
        } else {
          console.log('Web Share API를 사용할 수 없거나 파일 공유를 지원하지 않습니다. 다운로드를 시도합니다.');
          fallbackDownload(blob, fileName);
        }
      } else {
        // 데스크탑인 경우 항상 다운로드
        fallbackDownload(blob, fileName);
      }
    } catch (error) {
      console.error('이미지 생성 또는 공유에 실패했습니다.', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const fallbackDownload = (blob: Blob, fileName: string) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    console.log('이미지가 성공적으로 다운로드되었습니다.');
  };

  // Chip 관련 상태 및 refs (변경 없음)
  const chipRefs = useRef<(HTMLDivElement | null)[]>([]);
  chipRefs.current = [];

  const [chipHeight, setChipHeight] = useState<number | undefined>(undefined);

  const addChipRef = (el: HTMLDivElement | null) => {
    if (el && !chipRefs.current.includes(el)) {
      chipRefs.current.push(el);
    }
  };

  useEffect(() => {
    if (chipRefs.current.length === 0) return;

    const heights = chipRefs.current.map((chip) => chip?.offsetHeight || 0);
    const maxHeight = Math.max(...heights);

    if (maxHeight !== chipHeight) {
      setChipHeight(maxHeight);
    }
  }, [keywordList, chipHeight]);

  useEffect(() => {
    const handleResize = () => {
      if (chipRefs.current.length === 0) return;

      const heights = chipRefs.current.map((chip) => chip?.offsetHeight || 0);
      const maxHeight = Math.max(...heights);

      if (maxHeight !== chipHeight) {
        setChipHeight(maxHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [chipHeight]);

  useEffect(() => {
    if (componentRef.current && onHeightChange) {
      const height = componentRef.current.offsetHeight;
      onHeightChange(height);
    }
  }, [componentRef.current, onHeightChange]);

  // 일정한 높이를 유지하기 위한 fixedHeight 설정
  const fixedHeight = '600px'; // 필요에 따라 이 값을 조정하세요

  return (
    <Box
      ref={componentRef}
      sx={{
        width: '100%',
        borderRadius: '36px',
        textAlign: 'center',
        position: 'relative', // 절대 위치 자식 요소를 위해 상대 위치 설정
        overflow: 'hidden',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${gameResultQueue?.game_prediction?.game_prediction_group?.background_image_url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat-y',
        backgroundPosition: 'center',
        zIndex: 1,
        minHeight: fixedHeight, // 일정한 높이 유지
      }}
    >
      {/* gameResultQueue가 null일 때 Skeleton 표시 */}
      {!gameResultQueue ? (
        <Skeleton
          variant="rectangular"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 999,
            bgcolor: 'rgba(0, 0, 0, 0.3)',
          }}
        />
      ) : (
        // gameResultQueue에 값이 있을 때 콘텐츠 렌더링
        <Box sx={{ position: 'relative', zIndex: 2 }}>
          {!isDownloading && (
            <IconButton
              onClick={handleDownload}
              aria-label="download"
              sx={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                width: isMdDown ? '32px' : '48px',
                height: isMdDown ? '32px' : '48px',
                bgcolor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                zIndex: 5, // 모든 요소 위에 있도록 높은 z-index 설정
                transition: 'background-color 0.3s',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 1)',
                },
              }}
            >
              <img
                src={DownloadIcon}
                style={{
                  width: isMdDown ? '20px' : '28px',
                  height: isMdDown ? '20px' : '28px',
                }}
              />
            </IconButton>
          )}

          <Box sx={{ height: isMdDown ? '32px' : '30px' }} />

          {/* no. */}
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: isMdDown ? '15px' : '15px',
              color: '#505050',
            }}
          >
            no.{gameResultQueue?.game_prediction?.game_prediction_group?.number}
          </Typography>

          <Box sx={{ height: isMdDown ? '12px' : '16px' }} />

          {/* Title */}
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: isMdDown ? '24px' : '28px',
              color: 'black',
              textAlign: 'center',
              padding: { xs: '0 24px', sm: '0 30px' },
            }}
          >
            {gameResultQueue?.game_prediction?.title}
          </Typography>

          <Box sx={{ height: isMdDown ? '16px' : '20px' }} />

          {/* Subtitle */}
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: isMdDown ? '20px' : '24px',
              color: 'black',
              textAlign: 'center',
              padding: { xs: '0 24px', sm: '0 30px' },
            }}
          >
            {gameResultQueue?.game_prediction?.title_summary}
          </Typography>

          <Box sx={{ height: isMdDown ? '8px' : '12px' }} />

          {/* Result Summary */}
          <Box sx={{ display: 'inline-block', position: 'relative', textAlign: 'center' }}>
            <Box
              sx={{
                position: 'absolute',
                top: 'calc(100% - 18px)',
                left: '-18px',
                right: '-18px',
                height: '20px',
                backgroundColor: alpha(gameResultQueue?.game_prediction?.game_prediction_group?.main_color ?? '#FFFFFF', 0.7),
                zIndex: 1, // 배경 레이어를 위한 낮은 z-index
              }}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: isMdDown ? '24px' : '24px',
                color: gameResultQueue?.game_prediction?.game_prediction_group?.sub_color,
                zIndex: 3, // 텍스트를 위한 높은 z-index
                position: 'relative',
              }}
            >
              {gameResultQueue?.game_prediction_result?.result_summary}
            </Typography>
          </Box>

          <Box sx={{ height: isMdDown ? '28px' : '24px' }} />

          {/* Main Image(s) */}
          {hasSecondImage ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                width: '100%',
                zIndex: 2,
                px: isMdDown ? '32px' : '32px',
                py: isMdDown ? '12px' : '12px',
              }}
            >
              {/* First Image */}
              <Box
                sx={{
                  flex: '1 1 0',
                  position: 'relative',
                  width: '100%',
                  maxWidth: 'calc(50% - 6px)', // Adjust for gap
                  aspectRatio: '1 / 1',
                  borderRadius: isMdDown ? '12px' : '16px',
                  overflow: 'hidden',
                  border: '2px solid white',
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  zIndex: 2, // Lower z-index for images
                }}
              >
                <img
                  src={gameResultQueue?.game_prediction_result?.image_url_1 ?? ''}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 3, // Ensure images are above background but below texts
                  }}
                />
              </Box>
              {/* Second Image */}
              <Box
                sx={{
                  flex: '1 1 0',
                  position: 'relative',
                  width: '100%',
                  maxWidth: 'calc(50% - 6px)', // Adjust for gap
                  aspectRatio: '1 / 1',
                  borderRadius: isMdDown ? '12px' : '16px',
                  overflow: 'hidden',
                  border: '2px solid white',
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  zIndex: 2, // Lower z-index for images
                }}
              >
                <img
                  src={gameResultQueue?.game_prediction_result?.image_url_2 ?? ''}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 3, // Ensure images are above background but below texts
                  }}
                />
              </Box>
            </Box>
          ) : (
            // Single Image
            <Box
              sx={{
                position: 'relative',
                width: isMdDown ? 'calc(100% - 184px)' : 'calc(100% - 240px)',
                paddingTop: isMdDown ? 'calc(100% - 184px)' : 'calc(100% - 240px)',
                borderRadius: isMdDown ? '12px' : '16px',
                overflow: 'hidden',
                margin: '0 auto',
                border: '2px solid white',
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                zIndex: 2, // Lower z-index for images
              }}
            >
              <img
                src={gameResultQueue?.game_prediction_result?.image_url_1 ?? ''}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  zIndex: 3, // Ensure images are above background but below texts
                }}
              />
            </Box>
          )}

          <Box sx={{ height: isMdDown ? '24px' : '36px' }} />

          {/* Left Image and Text */}
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            sx={{
              borderRadius: '8px',
              width: '80%',
              mx: 'auto',
              paddingLeft: 0,
              paddingRight: 0,
              zIndex: 3, // Higher z-index for text elements
            }}
          >
            <Box
              component="img"
              src={gameResultQueue?.game_prediction?.game_prediction_group?.sub_image_url ?? ''}
              sx={{
                width: 64,
                height: 48,
                marginLeft: '0px',
                zIndex: 4, // Ensure icon is above background
              }}
            />

            <Box
              sx={{
                position: 'relative',
                borderRadius: '8px',
                padding: '10px 12px',
                marginLeft: isMdDown ? '12px' : '16px',
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 3,
                background: `linear-gradient(to right bottom, ${colors.gradientColors.join(', ')})`, // 배경 그라데이션
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: isMdDown ? '15px' : '18px',
                  whiteSpace: 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  color: getContrastingTextColor(gameResultQueue?.game_prediction?.game_prediction_group?.main_color),
                  textAlign: 'center',
                  lineHeight: 1.5,
                }}
              >
                {gameResultQueue?.game_prediction_result?.summary_phrase}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ height: '16px' }} />

          {/* Keyword List */}
          <Stack
            direction="row"
            spacing={isMdDown ? '6px' : '8px'}
            justifyContent="center"
            alignItems="flex-start"
            sx={{
              flexWrap: 'nowrap',
              zIndex: 3, // Higher z-index for text elements
              position: 'relative',
              maxWidth: '100%',
              overflowX: 'auto',
              paddingX: '20px',
            }}
          >
            {keywordList.map((tag, index) => (
              <Chip
                key={index}
                label={`#${tag}`}
                ref={addChipRef}
                sx={{
                  backgroundColor: colors.brightColor,
                  fontWeight: 400,
                  fontSize: isMdDown ? '12px' : '18px',
                  color: '#111111',
                  border: '1px solid white',
                  height: chipHeight ? `${chipHeight}px` : 'auto',
                  minHeight: 'auto',
                  padding: isMdDown ? '8px 2px' : '9.5px 2px',
                  borderRadius: '999px',
                  '& .MuiChip-label': {
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                    textAlign: 'center',
                  },
                }}
              />
            ))}
          </Stack>

          <Box sx={{ height: isMdDown ? '28px' : '26px' }} />

          {/* From */}
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: isMdDown ? '14px' : '18px',
              color: gameResultQueue?.game_prediction?.game_prediction_group?.sub_color,
              zIndex: 3, // Higher z-index for text
              position: 'relative',
            }}
          >
            From. {gameResultQueue?.game_speaker?.name}
          </Typography>
          <Box sx={{ height: isMdDown ? '24px' : '40px' }} />
        </Box>
      )}
    </Box>
  );
});

export default ResultSummaryCard;
